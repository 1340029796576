import { LogoutOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { Button, Flex, Result, ResultProps } from "antd";
import { createContext, PropsWithChildren, useEffect, useState } from "react";

import { Customer, DefaultTimeSeries, useCustomersQuery } from "@/api";
import Loading from "@/components/loading";
import { QUERY_SETTINGS } from "@/constants";
import { LoadingContainer } from "@/features/dashboard/Dashboard.styled";
import { getCookie, setCookie } from "@/utils/cookies";
import { constructBaseAppUrl, getCurrentCustomerId } from "@/utils/customers";
import { CommonDataDictionary } from "@/utils/types/commonDataDictionary";

export interface CustomerContextValue {
  currentCustomer?: Customer;
  customers: Customer[];
  commonData: CommonDataDictionary;
}

export const CustomerContext = createContext<CustomerContextValue>({
  customers: [],
  commonData: {},
});

export const ResultWithLogout = (props: ResultProps) => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <Flex vertical align="center">
      <Result {...props} />
      <Button onClick={handleLogout}>
        <LogoutOutlined />
        Logout
      </Button>
    </Flex>
  );
};

// Little hack to get defaultTimeSeries from outside components and hooks
export const defaultTimeSeriesFieldsByCustomerId = new Map<string, DefaultTimeSeries[]>();
export const defaultEventAnalysisTimeSeriesFieldsByCustomerId = new Map<string, DefaultTimeSeries[]>();

export default function CustomerProvider({ children }: PropsWithChildren<{}>) {
  // Checks if the user is authorized for the requested customer id and if not, redirect them.
  // Also sets CustomerListContext for reference by components like the customer picker
  const { data, isLoading, isFetched, isError } = useCustomersQuery({}, { staleTime: QUERY_SETTINGS.SHORT_STALE_TIME });
  const [authorizedCustomer, setAuthorizedCustomer] = useState<CustomerContextValue>();

  useEffect(() => {
    async function getCustomer() {
      if (isFetched && !authorizedCustomer) {
        const customerId = getCurrentCustomerId();
        const customer = data?.customers.find((c: Customer) => c.id === customerId);

        if (data && customer) {
          const jsonCommonData = customer.commonResourcesUrl
            ? await fetch(`/${customer.commonResourcesUrl}`)
            : undefined;

          const commonData = jsonCommonData ? await jsonCommonData.json() : ({} as CommonDataDictionary); // TODO: Type this.

          // If the CUSTOMER_VERSION cookie does not match the version for this customer, reload the app (unless
          // CUSTOMER_VERSION_OVERRIDE is present). Note that this logic is independent of the actual loaded app
          // version, which is un-aliased.
          const versionOverrideCookie = getCookie("CUSTOMER_VERSION_OVERRIDE");
          const versionCookie = getCookie("CUSTOMER_VERSION") ?? "latest"; // latest is the default in the Lambda
          const appVersion = customer.appVersion ?? "latest";
          if (!versionOverrideCookie && appVersion != versionCookie) {
            setCookie("CUSTOMER_VERSION", appVersion);
            window.location.reload();
          }

          setAuthorizedCustomer({
            currentCustomer: customer,
            customers: data.customers,
            commonData,
          });
          defaultTimeSeriesFieldsByCustomerId.set(customer.id, customer.defaultTimeSeriesFields || []);
          defaultEventAnalysisTimeSeriesFieldsByCustomerId.set(customer.id, customer.defaultEventAnalysisFields || []);

          if (customer.features) {
            // Set feature flags as tags on Sentry
            Sentry.setTags(Object.fromEntries(customer.features.map((f) => [f.id, true])));
          }
        } else {
          const defaultCustomer = data?.customers[0];
          if (defaultCustomer?.id) {
            window.location.href = constructBaseAppUrl(defaultCustomer?.id);
          }
        }
      }
    }
    getCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, data]);

  if (authorizedCustomer) {
    return <CustomerContext.Provider value={authorizedCustomer}>{children}</CustomerContext.Provider>;
  } else if ((data?.customers.length ?? 0) === 0 && !isLoading) {
    return (
      <ResultWithLogout
        status="error"
        title="No customer permissions found"
        subTitle="We could not find any authorized customer apps for your account."
      />
    );
  } else if (isError) {
    return (
      <ResultWithLogout
        status="error"
        title="Sorry, something went wrong."
        subTitle="Could not get the list of authorized customer apps from the server."
      />
    );
  }
  return (
    <LoadingContainer>
      <Loading fullHeight />
    </LoadingContainer>
  );
}
