import { isEqual } from "lodash";
import { createContext, ReactNode, useState } from "react";

interface HistoryProviderProps {
  children: ReactNode;
}

interface HistoryContextType<T> {
  currentRevision: T;
  undo: () => T;
  redo: () => T;
  addRevision: (revision: T) => T;
  setRevisionIndex: (index: number) => T;
  isRedoAvaliable: boolean;
  isUndoAvaliable: boolean;
}

export const HistoryContext = createContext<HistoryContextType<any>>({
  currentRevision: "",
  undo: () => {},
  redo: () => {},
  addRevision: (_revision) => {},
  setRevisionIndex: (_index) => {},
  isRedoAvaliable: false,
  isUndoAvaliable: false,
});

export const HistoryProvider = <T,>({ children }: HistoryProviderProps) => {
  const [revisionHistory, setRevisionHistory] = useState<T[]>([]);
  // -1 because the first time something gets added it will increment to 1;
  const [revisionIndex, setRevisionIndex] = useState(-1);

  const undo = () => {
    const newIndex = Math.max(0, revisionIndex - 1);
    setRevisionIndex(newIndex);
    return revisionHistory[newIndex];
  };

  const redo = () => {
    const newIndex = Math.min(revisionHistory.length - 1, revisionIndex + 1);
    setRevisionIndex(newIndex);
    return revisionHistory[newIndex];
  };

  const addRevision = (revision: T) => {
    // prevents duplicate value from being recorded
    if (isEqual(revision, revisionHistory[revisionIndex])) return revision;
    const newHistory = [...revisionHistory.slice(0, revisionIndex + 1), revision];
    setRevisionHistory(newHistory);
    setRevisionIndex(revisionIndex + 1);
    return revision;
  };

  const safelySetRevisionIndex = (index: number) => {
    const safeIndex = Math.max(0, Math.min(revisionHistory.length - 1, index));
    setRevisionIndex(safeIndex);
    return revisionHistory[safeIndex];
  };

  return (
    <HistoryContext.Provider
      value={{
        undo,
        isUndoAvaliable: !(revisionIndex === 0),
        redo,
        isRedoAvaliable: !(revisionIndex === revisionHistory.length - 1),
        addRevision,
        currentRevision: revisionHistory[revisionIndex],
        setRevisionIndex: safelySetRevisionIndex,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};
