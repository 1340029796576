import { createFileRoute } from "@tanstack/react-router";

import { AnomalyDetectorEditorPage } from "@/features/anomaly/editor/AnomalyDetectorEditorPage";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export const Route = createFileRoute("/anomaly-detector/new")({
  component: () => (
    <AppMainLayout>
      <AnomalyDetectorEditorPage />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("New Detector"),
});
