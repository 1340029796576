import { createFileRoute } from "@tanstack/react-router";

import { EventType } from "@/api";
import EventAnalyzer from "@/features/event/EventAnalyzer";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";
import { EventAnalysisSearch } from "./event-analysis";

export const Route = createFileRoute("/pattern-event/$patternEventId")({
  component: () => (
    <AppMainLayout>
      <EventAnalyzer eventType={EventType.Expression} />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Pattern Event"),
  validateSearch: (search: Record<string, unknown>): EventAnalysisSearch => ({
    xEventAnalysisChart: (search.xEventAnalysisChart as string) || undefined,
    eventOccurrencesPage: (search.eventOccurrencesPage as number) || undefined,
    relatedEventsPage: (search.relatedEventsPage as number) || undefined,
  }),
});
