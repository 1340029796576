import { createFileRoute } from "@tanstack/react-router";

import SavedExpressionEditorPage from "@/features/savedExpression/SavedExpressionEditorPage";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export const Route = createFileRoute("/pattern/$patternId")({
  component: () => (
    <AppMainLayout>
      <SavedExpressionEditorPage />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Pattern"),
});
