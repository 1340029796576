import { validateExpression } from "@lib/src/expression/expressionValidator";
import { expressionReferencedTimeSeriesFields } from "@lib/src/expression/referencedTimeSeriesFields";
import { Expression } from "@lib/src/expression/types";
import { isNotUndefined } from "@lib/src/isNotUndefined";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Alert, Button, Card, Col, Flex, Form, Input, Modal, notification, Row, Spin, Typography } from "antd";
import { useContext, useEffect, useState } from "react";

import {
  Dependency,
  useCreateSavedExpressionMutation,
  useDeleteSavedExpressionMutation,
  useGetSavedExpressionByIdQuery,
  useGetSavedExpressionsQuery,
  useSavedExpressionsQuery,
  useTimeSeriesFieldsQuery,
  useUpdateSavedExpressionMutation,
} from "@/api";
import { HistoryContext } from "@/contexts/HistoryProvider";
import { AfterCreate } from "@/features/anomaly/editor/AfterCreate";
import { requireCurrentCustomerId } from "@/utils/customers";
import { humanFriendlyStatuses, isPatternCalculating } from "@/utils/patterns";

import { showModalDeleteDependencies } from "../dependency/DependencyModal";
import { TopHeadingWithButtonsForAnomalyAndPattern } from "../pageContent/TopHeadingWithButtonsForAnomalyAndPattern";
import { ButtonsContainer } from "../ui/Editor.styled";
import { createExpression } from "./createExpression";
import { ExpressionInput } from "./ExpressionInput";
import { useFindUnavailableDefinitions } from "./hooks/useFindUnavailableDefinitions";
import { SaveAsModal } from "./SaveAsModal";
import { SavedExpressionPreview } from "./savedExpressionPreview/SavedExpressionPreview";
import { useExpressionTreeBuilder } from "./useExpressionTreeBuilder";

export type SavedExpressionEditorProps = {
  expressionId?: string;
  afterCreate?: AfterCreate;
};

export type FormValues = {
  name: string;
  description: string | null | undefined;
};

export const SavedExpressionEditor = (props: SavedExpressionEditorProps) => {
  const queryClient = useQueryClient();
  const [modal, contextHolder] = Modal.useModal();

  const [form] = Form.useForm<FormValues>();
  const { expressionId, afterCreate } = props;
  const { rootExpression, editExpression } = useExpressionTreeBuilder();

  const [submittable, setSubmittable] = useState(false);
  const [formulaValid, setFormulaValid] = useState(false);
  const [isSaveAsModalVisible, setIsSaveAsModalVisible] = useState(false);
  const { addRevision, undo, isUndoAvaliable, redo, isRedoAvaliable } = useContext(HistoryContext);
  const {
    data,
    refetch,
    isLoading: isLoadingGetExpression,
  } = useGetSavedExpressionByIdQuery(
    {
      customerId: requireCurrentCustomerId(),
      expressionId: expressionId!,
    },
    { enabled: !!expressionId }
  );

  const expression = data?.savedExpressions?.savedExpression;

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => setSubmittable(formulaValid))
      .catch(() => setSubmittable(false));
  }, [form, values, formulaValid]);

  useEffect(() => {
    if (expression) {
      resetToBlankOrLastSaved();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expression]);

  useEffect(() => {
    addRevision(rootExpression);
  }, [rootExpression]);

  const [notificationApi, notificationContextHolder] = notification.useNotification();

  const createSavedExpressionMutation = useCreateSavedExpressionMutation();
  const updateSavedExpressionMutation = useUpdateSavedExpressionMutation();
  const deleteSavedExpressionMutation = useDeleteSavedExpressionMutation();

  const isLoading =
    (!!expressionId && isLoadingGetExpression) ||
    createSavedExpressionMutation.isLoading ||
    updateSavedExpressionMutation.isLoading;

  const isDeleting = deleteSavedExpressionMutation.isLoading;

  const navigate = useNavigate();

  const invalidateQueries = () => {
    invalidateSavedExpressionQuery();
    invalidateTimeSeriesFieldsQuery();
    invalidateSavedExpressionOptions();
    invalidateExpressionById();
  };

  const invalidateSavedExpressionOptions = () => {
    queryClient.invalidateQueries({
      queryKey: useSavedExpressionsQuery.getKey({
        customerId: requireCurrentCustomerId(),
      }),
    });
  };

  const invalidateSavedExpressionQuery = () => {
    queryClient.invalidateQueries({
      queryKey: useGetSavedExpressionsQuery.getKey({
        customerId: requireCurrentCustomerId(),
      }),
    });
  };

  const invalidateTimeSeriesFieldsQuery = () => {
    queryClient.invalidateQueries({
      queryKey: useTimeSeriesFieldsQuery.getKey({
        customerId: requireCurrentCustomerId(),
      }),
    });
  };

  const invalidateExpressionById = () => {
    queryClient.invalidateQueries({
      queryKey: useGetSavedExpressionByIdQuery.getKey({
        customerId: requireCurrentCustomerId(),
        expressionId: expressionId!,
      }),
    });
  };

  const { patternDefinition, patternsAreNotAvailable } = useFindUnavailableDefinitions({ rootExpression });

  const onCreateExpression = async () => {
    const formValues = form.getFieldsValue();

    return createExpression(
      formValues.name,
      formValues.description,
      rootExpression as Expression,
      createSavedExpressionMutation,
      notificationApi,
      setIsSaveAsModalVisible,
      afterCreate
    )
      .then(invalidateQueries)
      .then(() => navigate({ to: "/pattern" }));
  };

  const onCancelOperation = () => {
    invalidateQueries();
    navigate({ to: "/pattern" });
  };

  const onUpdateExpression = async () => {
    const formValues = form.getFieldsValue();
    try {
      validateExpression(rootExpression as Expression);
      updateSavedExpressionMutation.mutate(
        {
          customerId: requireCurrentCustomerId(),
          expression: {
            name: formValues.name ?? "",
            description: formValues.description ?? "",
            definition: rootExpression as Expression,
          },
          id: expression!.id,
          version: expression!.version,
        },
        {
          onSuccess(data) {
            notificationApi.success({
              message: `Pattern ${data.savedExpressions!.updateSavedExpression.name} updated.`,
            });
            invalidateQueries();
            refetch();
          },
        }
      );
    } catch (e) {
      notificationApi.error({ message: "Invalid pattern." });
    }
  };

  const onDeleteExpression = async () => {
    try {
      if (!expression?.creator?.isMe) return;
      deleteSavedExpressionMutation.mutate(
        {
          customerId: requireCurrentCustomerId(),
          id: expressionId!,
          version: data!.savedExpressions!.savedExpression!.version,
        },
        {
          onSuccess({ savedExpressions }) {
            const dependencies = savedExpressions?.deleteSavedExpression.dependencies;
            if (dependencies?.length) {
              showModalDeleteDependencies(
                modal,
                dependencies as Dependency[],
                "It can't be deleted",
                "Sorry, This Pattern has dependencies."
              );
            } else {
              notificationApi.success({ message: `Pattern ${expression!.name} deleted.` });
              invalidateQueries(); // Invalidate all queries
              navigate({ to: "/pattern" });
            }
          },
        }
      );
    } catch (e) {
      notificationApi.error({ message: "Invalid pattern" });
    }
  };

  const isReadOnly = isLoading || (expression && (expression.readOnly || !expression.creator?.isMe)) || false;

  const handleExpressionChange = (valid: boolean) => setFormulaValid(valid);

  const resetToBlankOrLastSaved = () => {
    editExpression(expression?.definition ?? {}, []);
    form.setFieldValue("formula", expression?.definition ?? "");
    form.setFieldValue("name", expression?.name ?? "");
    form.setFieldValue("description", expression?.description ?? "");
  };

  const currentF = rootExpression ? expressionReferencedTimeSeriesFields(rootExpression as Expression) : [];

  //TODO: currentInputIds in this case excludes signals, because i cant convert them to fully qualified names in frontend; but since they cannot be circular, i dont need them here
  const currentInputIds = currentF.map((f) => (f.type != "Signal" ? f.value : undefined)).filter(isNotUndefined);

  const isSubmitButtonDisable = isDeleting || isLoading || !submittable;

  return (
    <Flex vertical>
      {contextHolder}
      {isDeleting && <Spin fullscreen />}
      <TopHeadingWithButtonsForAnomalyAndPattern
        isNew={!expressionId}
        isOwnedByMe={expression?.creator?.isMe || false}
        isCalculating={isPatternCalculating(expression?.status!)}
        itemType="Pattern"
        hideButtons={isLoadingGetExpression && !!expressionId}
        onSaveAs={() => setIsSaveAsModalVisible(true)}
        onCreate={onCreateExpression}
        onDelete={onDeleteExpression}
        onUpdate={onUpdateExpression}
        onCancel={onCancelOperation}
        {...{ isLoading, isReadOnly, isDisabled: isSubmitButtonDisable }}
      />
      <Row gutter={[24, 2]}>
        <Col md={12} xxl={10}>
          <Card
            title={
              <Flex vertical={false} justify="space-between" align="center">
                <span>Pattern Definition</span>
                {expression && (
                  <Typography.Text type="secondary">
                    Status: {humanFriendlyStatuses(expression?.status)}
                  </Typography.Text>
                )}
              </Flex>
            }
            bordered={false}
          >
            <Form layout="vertical" form={form}>
              <Flex gap={8} vertical>
                <Form.Item
                  required
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the pattern name",
                    },
                  ]}
                >
                  <Input placeholder="name" disabled={isReadOnly ?? false} name="name" />
                </Form.Item>
                <Form.Item label="Description" name="description">
                  <Input placeholder="description" disabled={isReadOnly ?? false} name="description" />
                </Form.Item>
                <Form.Item
                  required
                  label="Formula"
                  name="formula"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the pattern formula",
                    },
                  ]}
                >
                  <ExpressionInput
                    expression={patternsAreNotAvailable ? {} : rootExpression}
                    onChange={isReadOnly ? undefined : editExpression}
                    disabled={isReadOnly}
                    path={[]}
                  />
                  {patternsAreNotAvailable && (
                    <Alert
                      style={{
                        marginLeft: "1.5rem",
                      }}
                      showIcon
                      message={
                        patternDefinition
                          ? `${patternDefinition.name} or one of its dependencies was changed and is currently calculating. Please wait until it is
                      finished or select another formula. If the problem persists please contact support.`
                          : `Looks like the associated definition is not more available or was deleted. Please select create a new formula, or contact support.`
                      }
                      type="info"
                    ></Alert>
                  )}
                </Form.Item>
              </Flex>
              {!isReadOnly && (
                <ButtonsContainer>
                  <Button
                    onClick={() => {
                      editExpression(undo(), []);
                    }}
                    disabled={!isUndoAvaliable}
                  >
                    Undo
                  </Button>
                  <Button
                    onClick={() => {
                      editExpression(redo(), []);
                    }}
                    disabled={!isRedoAvaliable}
                  >
                    Redo
                  </Button>
                  <Button
                    onClick={resetToBlankOrLastSaved}
                    disabled={isLoading || (!submittable && !expressionId)}
                    type="default"
                  >
                    Reset
                  </Button>
                </ButtonsContainer>
              )}
            </Form>
            {expression && (
              <SaveAsModal
                title="Save Pattern As..."
                originalData={{
                  name: expression.name,
                  description: expression.description ?? "",
                }}
                isVisible={isSaveAsModalVisible}
                onOk={(expression) =>
                  createExpression(
                    expression.name,
                    expression.description,
                    rootExpression as Expression,
                    createSavedExpressionMutation,
                    notificationApi,
                    setIsSaveAsModalVisible,
                    afterCreate
                  ).then(invalidateQueries)
                }
                onCancel={() => setIsSaveAsModalVisible(false)}
                isLoading={createSavedExpressionMutation.isLoading}
              />
            )}
            {notificationContextHolder}
          </Card>
        </Col>
        <Col md={12} xxl={14}>
          <Card title="Preview" bordered={false} style={{ height: "100%" }}>
            <SavedExpressionPreview
              expressionId={expressionId}
              currentInputIds={currentInputIds}
              expressionDefinition={patternsAreNotAvailable ? {} : rootExpression}
              onExpressionChange={handleExpressionChange}
            />
          </Card>
        </Col>
      </Row>
    </Flex>
  );
};
