import { useNavigate } from "@tanstack/react-router";
import { Card, Col, Row, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { EventType, FeatureId } from "@/api";
import { EventAnalysisGraph } from "@/components/event/EventAnalysisGraph";
import EventHeader from "@/components/event/EventHeader";
import Loading from "@/components/loading";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { EVENT_ANALYSIS_CHART } from "@/constants";
import { ChartColoringPoolProvider } from "@/contexts/ChartColoringPoolProvider";
import { EventAnalysisChartActions } from "@/contexts/EventAnalysisChartSlice";
import {
  selectAnalysisSeriesIds,
  selectAnalysisSignals,
  selectColoringPoolRecord,
  selectEventFilter,
} from "@/contexts/EventAnalysisChartStore";
import { useEventAnalysisParams } from "@/hooks/useEventAnalysisParams";
import useStateToExternalSource from "@/hooks/useStateToExternalSource";
import { RequireFeatureFlag } from "@/utils/features";

import { EventAnalyzerProps } from "./EventAnalyzer";
import EventFilterManager from "./EventFilterManager";
import EventSummary, { EventOccurrences } from "./EventSummary";
import { EventTimeLine } from "./EventTimeLine";
import { useExpressionStatus } from "./hooks/useExpressionStatus";
import { useSavedVisitedEvents } from "./hooks/useSavedVisitedEvents";
import RelatedEvents from "./RelatedEventTable/RelatedEvents";

const EventAnalysisLoading = () => (
  <Card className="card-pullsystems-shadow">
    <Loading height={256} />
  </Card>
);

function StatefulEventAnalysisGraph() {
  const dispatch = useDispatch();

  const statefulColoringPoolRecord = useSelector(selectColoringPoolRecord);
  const usedSeriesIds = useSelector(selectAnalysisSeriesIds);

  const statefulUpdatePoolChange = (payload: { record: Record<string, string> }) => {
    dispatch(EventAnalysisChartActions.updateColoringPool(payload));
  };

  return (
    <ChartColoringPoolProvider
      statefulColoringPoolRecord={statefulColoringPoolRecord}
      usedSeriesIds={usedSeriesIds}
      statefullyUpdatePoolChange={statefulUpdatePoolChange}
    >
      <EventAnalysisGraph />
    </ChartColoringPoolProvider>
  );
}

const EventAnalyzerContent = (props: EventAnalyzerProps) => {
  const eventParams = useEventAnalysisParams();
  const showEventTimeLine = props.eventType === EventType.Fault;
  const { selectedEventFilter: eventFilter } = useSelector(selectEventFilter);
  const { isLoading: isVisitedLoading, setEventFilter, cluster, expression } = useSavedVisitedEvents();
  const { isLoading: isStatusLoading, isNotAvailable } = useExpressionStatus(eventFilter?.expressionEventId ?? "");

  const enventAnalysisNavigate = useNavigate();

  const handleEventAnalysisChartStateNavigation = (serializedState: string) => {
    enventAnalysisNavigate({ search: (prev) => ({ ...prev, xEventAnalysisChart: serializedState }), replace: true });
  };

  useStateToExternalSource({
    stateKey: EVENT_ANALYSIS_CHART,
    selector: selectAnalysisSignals,
    onStateChange: handleEventAnalysisChartStateNavigation,
  });

  React.useEffect(() => {
    if (eventFilter && !isVisitedLoading) {
      setEventFilter(eventFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventFilter, eventParams]);

  if (eventFilter == undefined || isStatusLoading) {
    return (
      <>
        <EventAnalysisLoading />;
        <EventFilterManager {...props} />
      </>
    );
  }

  const isUnavailablePatternEvent = eventFilter?.expressionEventId && isNotAvailable;

  if (isStatusLoading && eventFilter?.expressionEventId)
    return (
      <Card>
        <Loading height={256} />
      </Card>
    );

  return (
    <>
      <EventHeader eventFilter={eventFilter} cluster={cluster} expression={expression} />
      {isUnavailablePatternEvent ? (
        <BasicWidget title="Pattern Event Unavailable">
          <div style={{ padding: "1em" }}>
            <Typography.Text type="secondary" style={{ margin: "1em" }}>
              The pattern event you have chosen is still being calculated. Please check back shortly.
            </Typography.Text>
          </div>
        </BasicWidget>
      ) : (
        <>
          <StatefulEventAnalysisGraph />
          <RequireFeatureFlag id={FeatureId.Clusters}>{showEventTimeLine && <EventTimeLine />}</RequireFeatureFlag>
          <EventSummary />
          <Row gutter={[24, 24]}>
            <Col span={24} xxl={12}>
              <EventOccurrences />
            </Col>
            <Col span={24} xxl={12}>
              <RelatedEvents />
            </Col>
          </Row>
        </>
      )}
      <EventFilterManager {...props} />
    </>
  );
};

export default React.memo(EventAnalyzerContent);
