import { Link } from "@tanstack/react-router";
import styled from "styled-components";

export const PatternLinkContainer = styled(Link)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 20px;
    width: 20px;
  }
`;
