import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import { ColumnType } from "antd/es/table";
import { camelCase, uniqBy } from "lodash";
import { useContext, useEffect, useState } from "react";

import {
  MaintenanceLog,
  MaintenanceLogsColumns,
  MaintenanceLogsExclusionsInput,
  Pagination,
  useMaintenanceLogsColumnByUserInputQuery,
  useMaintenanceLogsQuery,
} from "@/api/customerApi";
import { BasicTable } from "@/components/tables/BasicTable";
import { TableFilter } from "@/components/tables/filter/TableFilter";
import { compareText } from "@/components/tables/sorting";
import { useRemoteFiltering } from "@/components/tables/useRemoteFiltering";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { useTablePagination } from "@/hooks/useTablePagination";
import { parseDateToStr } from "@/utils/dateUtils";
import { toModelDescription } from "@/utils/vehicleModel";

const fields = Object.values(MaintenanceLogsColumns);

export const MaintenanceLogs = () => {
  const { customerIdentifier } = useCustomerIdentifier();
  const {
    currentlySearching,
    setSearchedFilteringOptions,
    filteringOptions,
    handleFilterInputChangeUndebounced,
    filteringSelecteds,
  } = useRemoteFiltering({ fields });

  const [exclusions, setExclusions] = useState<MaintenanceLogsExclusionsInput>({
    ...(selectedColumnToFilteringInput(filteringSelecteds) as MaintenanceLogsExclusionsInput),
  });

  const { globalFilter } = useContext(GlobalFilterContext);

  const { sortersInput, onChange } = useTablePagination<MaintenanceLog>();

  const currentPage = useSearch({ strict: false, select: (search) => search.maintenanceLogsPage });
  const navigate = useNavigate();

  const handlePaginationChange = (page: number) => {
    navigate({ search: (prev) => ({ ...prev, maintenanceLogsPage: page }) });
  };

  const pagination: Pagination = {
    currentPage: currentPage || 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  const { data, isLoading } = useMaintenanceLogsQuery(
    { filter: globalFilter, pagination, sort: sortersInput, exclusions: exclusions },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const { data: currentFilteringOptionsData, isFetching: isFetchingFilteringOptions } =
    useMaintenanceLogsColumnByUserInputQuery(
      {
        filter: globalFilter,
        input: currentlySearching?.content ?? "",
        column: currentlySearching?.field as MaintenanceLogsColumns,
        exclusions,
      },
      { enabled: !!currentlySearching?.field }
    );

  useEffect(() => {
    if (!currentlySearching) {
      return;
    }
    const newOptions = (currentFilteringOptionsData?.filteredQuery.maintenanceLogColumnByUserInput ?? []).map(
      (option) => ({
        value: option,
        label: option,
      })
    );
    setSearchedFilteringOptions({ field: currentlySearching.field, options: newOptions });
  }, [setSearchedFilteringOptions, currentlySearching, currentFilteringOptionsData]);

  const getFilteringOptions = (fieldName: MaintenanceLogsColumns) => {
    const availableOptions = filteringOptions[fieldName] as { value: string; label: string }[];
    const previouslyUnselectedValues = exclusions[camelCase(fieldName) as keyof MaintenanceLogsExclusionsInput] ?? [];
    const previouslyUnselectedOptions = previouslyUnselectedValues.map((value) => ({
      value: value || "",
      label: value || "",
    }));
    const options = [...previouslyUnselectedOptions, ...availableOptions].sort((a, b) => compareText(a.label, b.label));
    const uniqueOptions = uniqBy(options, "value");
    return uniqueOptions;
  };

  const handleFilterDropdownOpenChangeFn = (fieldName: MaintenanceLogsColumns) => (open: boolean) => {
    if (open) {
      handleFilterInputChangeUndebounced(fieldName, "");
    }
  };

  const handleExclusionsApplied = (fieldName: MaintenanceLogsColumns, values: string[]) => {
    setExclusions({ ...exclusions, [camelCase(fieldName)]: values });
    navigate({ search: (prev) => ({ ...prev, maintenanceLogsPage: 1 }) });
  };

  const columns: ColumnType<MaintenanceLog>[] = [
    {
      key: "pvin",
      // some feature flag or translate to choose vin or pvin
      title: "PVIN",
      dataIndex: "pvin",
      sorter: true,
      filtered: !!exclusions?.["pvin"]?.length,
      render: (_v, record) => <Link to={`/vehicle/${record.pvin}`}>{record.pvin}</Link>,
      onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.Pvin),
      filterDropdown({ confirm }) {
        return (
          <TableFilter
            label={"PVIN"}
            isLoading={isFetchingFilteringOptions}
            fieldName={MaintenanceLogsColumns.Pvin}
            options={getFilteringOptions(MaintenanceLogsColumns.Pvin)}
            onApplyExclusions={(fieldName, values) => {
              handleExclusionsApplied(fieldName, values);
              confirm();
            }}
          />
        );
      },
    },
    {
      key: "modelYear",
      title: "Model Year",
      dataIndex: "modelYear",
      sorter: true,
      filtered: !!exclusions?.["modelYear"]?.length,
      onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.ModelYear),
      filterDropdown({ confirm }) {
        return (
          <TableFilter
            label={"Model Year"}
            isLoading={isFetchingFilteringOptions}
            fieldName={MaintenanceLogsColumns.ModelYear}
            options={getFilteringOptions(MaintenanceLogsColumns.ModelYear)}
            onApplyExclusions={(fieldName, values) => {
              handleExclusionsApplied(fieldName, values);
              confirm();
            }}
          />
        );
      },
    },
    {
      key: "modelStyle",
      title: "Model",
      dataIndex: "modelStyle",
      sorter: true,
      filtered: !!exclusions?.["modelStyle"]?.length,
      render: (_v: string, row) => toModelDescription({ modelStyle: row.model }, customerIdentifier.models),
      onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.ModelStyle),
      filterDropdown({ confirm }) {
        return (
          <TableFilter
            label={"Model"}
            isLoading={isFetchingFilteringOptions}
            fieldName={MaintenanceLogsColumns.ModelStyle}
            options={getFilteringOptions(MaintenanceLogsColumns.ModelStyle).map((option) => {
              return {
                label: toModelDescription({ modelStyle: option.value }, customerIdentifier.models) ?? option.label,
                value: option.value,
              };
            })}
            onApplyExclusions={(fieldName, values) => {
              handleExclusionsApplied(fieldName, values);
              confirm();
            }}
          />
        );
      },
    },
    {
      key: "startDate",
      title: "Repair Start Date",
      dataIndex: "repairStartDate",
      sorter: true,
      render: (_v, row) => parseDateToStr(new Date(row.repairStartDate), false, true),
      onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.MaintenanceStart),
      filtered: !!exclusions?.["maintenanceStart"]?.length,
      filterDropdown({ confirm }) {
        return (
          <TableFilter
            label={"Repair Start Date"}
            isLoading={isFetchingFilteringOptions}
            fieldName={MaintenanceLogsColumns.MaintenanceStart}
            options={getFilteringOptions(MaintenanceLogsColumns.MaintenanceStart).map((option) => ({
              value: option.value,
              label: parseDateToStr(new Date(option.value), false, true),
            }))}
            onApplyExclusions={(fieldName, values) => {
              handleExclusionsApplied(fieldName, values);
              confirm();
            }}
          />
        );
      },
    },
    {
      key: "endDate",
      title: "Repair End Date",
      dataIndex: "repairEndDate",
      sorter: true,
      filtered: !!exclusions?.["maintenanceEnd"]?.length,
      render: (_v, row) => parseDateToStr(new Date(row.repairEndDate), false, true),
      onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.MaintenanceEnd),
      filterDropdown({ confirm }) {
        return (
          <TableFilter
            label={"Repair End Date"}
            isLoading={isFetchingFilteringOptions}
            fieldName={MaintenanceLogsColumns.MaintenanceEnd}
            options={getFilteringOptions(MaintenanceLogsColumns.MaintenanceEnd).map((option) => ({
              value: option.value,
              label: parseDateToStr(new Date(option.value), false, true),
            }))}
            onApplyExclusions={(fieldName, values) => {
              handleExclusionsApplied(fieldName, values);
              confirm();
            }}
          />
        );
      },
    },
    {
      key: "componentLocationId",
      title: "Failure Location",
      dataIndex: "componentLocationId",
      sorter: true,
      filtered: !!exclusions?.["componentLocationId"]?.length,
      render: (_value, row) => row.componentLocationId ?? "-",
      onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.ComponentLocationId),
      filterDropdown({ confirm }) {
        return (
          <TableFilter
            label={"Failure Location"}
            isLoading={isFetchingFilteringOptions}
            fieldName={MaintenanceLogsColumns.ComponentLocationId}
            options={getFilteringOptions(MaintenanceLogsColumns.ComponentLocationId)}
            onApplyExclusions={(fieldName, values) => {
              handleExclusionsApplied(fieldName, values);
              confirm();
            }}
          />
        );
      },
    },
  ];
  return (
    <BasicWidget className="table" title="Maintenance Logs">
      <BasicTable
        columns={columns}
        dataSource={data?.filteredQuery.maintenanceLogs.data}
        loading={isLoading}
        rowKey={(r) => `r.pvin${r.repairStartDate}${r.repairEndDate}`}
        onChange={(pagination, _filter, sorter) => {
          onChange(pagination, {}, sorter);
          handlePaginationChange(pagination.current || 1);
        }}
        pagination={{
          current: pagination.currentPage,
          total: data?.filteredQuery.maintenanceLogs.pagination?.totalCount,
          showSizeChanger: false,
        }}
      />
    </BasicWidget>
  );
};

function selectedColumnToFilteringInput(selection: Record<string, (string | number)[]>) {
  return Object.entries(selection).reduce((acc, [field, selecteds]) => ({ ...acc, [camelCase(field)]: selecteds }), {});
}
