import { Modal } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import { useEffect, useState } from "react";

import { ClusterRedshift, ClusterRedshiftCreateInput } from "@/api";
import { VehicleIdentifier } from "@/api/customerApi";
import Loading from "@/components/loading";

import { AddVehicleToCluster } from "./AddVehiclesToCluster";
import { AffectedVehicles, AffectedVehiclesFooter } from "./AffectedVehicles";
import { CopyCluster } from "./CopyModal";
import { MissingPvins } from "./MissingPvins";
import {
  missingPvinsButtonStyle,
  missingPvinsFooter,
  NewCluster,
  newClusterButtonStyle,
  newClusterFooter,
} from "./NewCluster";
import { ViewMissingPvins } from "./ViewMissingPvins";

const titleObj = {
  addVehiclesToCluster: "Add Vehicles to Cluster",
  vehiclesAffected: "Vehicles Affected",
  newCluster: "Create New Cluster",
  viewMissingPvins: "Clusters Missing PVINs",
  missingPvins: "Missing PVINs",
  copyCluster: "Copy Cluster",
};

export type ClusterModalView =
  | "newCluster"
  | "vehiclesAffected"
  | "addVehiclesToCluster"
  // The difference between missingPvins and viewMissingPvins is that missing pvins
  // is shown during creation or adding pvins to the cluster. While viewMissingPvins is when
  // the user wants to see what pvins are currently not found in the vehicles table.
  | "missingPvins"
  | "viewMissingPvins"
  | "copyCluster"
  | undefined;
type ClusterModalProps = {
  api: NotificationInstance;
  isOpen: boolean;
  onCancel: () => void;
  initialView: ClusterModalView;
  pvins?: VehicleIdentifier[];
  clusterId?: string;
  clusterVersion?: number;
  handleSuccess: () => void;
  handleDelete?: (pvinsToDelete: ClusterRedshiftCreateInput[]) => void;
  isLoading?: boolean;
};

export const ClusterModal = ({
  isOpen,
  onCancel,
  initialView,
  api,
  pvins,
  clusterId,
  clusterVersion,
  handleSuccess,
  handleDelete,
  isLoading,
}: ClusterModalProps) => {
  const [view, setView] = useState<ClusterModalView>();
  const [missingPvins, setMissingPvins] = useState<string[]>();
  const [repeatedCombinations, setRepeatedCombinations] = useState<ClusterRedshift[]>([]);

  useEffect(() => {
    setView(initialView);
  }, [isOpen, initialView]);

  const handleCancel = () => {
    onCancel();
    setMissingPvins(undefined);
    setView(undefined);
  };

  const getFooter = () => {
    if (view === "newCluster") {
      return newClusterFooter;
    } else if (view === "missingPvins") {
      return missingPvinsFooter;
    } else if (view === "vehiclesAffected") {
      return <AffectedVehiclesFooter onCancel={handleCancel} createNewCluster={() => setView("newCluster")} />;
    } else {
      return null;
    }
  };

  const getCancelButtonProps = () => {
    if (view === "newCluster") return newClusterButtonStyle;
    if (view === "missingPvins") return missingPvinsButtonStyle;
  };

  return (
    <Modal
      open={isOpen}
      onCancel={handleCancel}
      footer={getFooter()}
      cancelButtonProps={getCancelButtonProps()}
      destroyOnClose
      title={view ? titleObj[view] : ""}
    >
      {view === "newCluster" && (
        <NewCluster
          api={api}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          pvins={pvins}
          initialMissingPvins={missingPvins}
          initialRepeatedCombinations={repeatedCombinations}
        />
      )}
      {view === "vehiclesAffected" && !isLoading && <AffectedVehicles pvins={pvins ?? []} setView={setView} />}
      {view === "vehiclesAffected" && isLoading && <Loading />}
      {view === "addVehiclesToCluster" && !!clusterId && clusterVersion && (
        <AddVehicleToCluster
          api={api}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          clusterId={clusterId}
          clusterVersion={clusterVersion}
          setView={setView}
          setMissingPvins={setMissingPvins}
          setRepeatedCombinations={setRepeatedCombinations}
        />
      )}
      {view === "viewMissingPvins" && handleDelete && (
        <ViewMissingPvins pvins={pvins?.map((v) => v.id) ?? []} handleDelete={handleDelete} />
      )}
      {view === "copyCluster" && !!clusterId && (
        <CopyCluster api={api} onSuccess={handleSuccess} onCancel={handleCancel} clusterId={clusterId} />
      )}
      {view === "missingPvins" && (!!missingPvins?.length || repeatedCombinations.length) && (
        <MissingPvins missingPvins={missingPvins} repeatedCombinations={repeatedCombinations} />
      )}
    </Modal>
  );
};
